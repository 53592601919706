<template>
	<div class="container">
		<component :is="component" :class="urlValue == '' ? 'component-container':'component-container-full'"></component>
		<van-tabbar v-if="urlValue == ''" v-model="component">
			<van-tabbar-item v-for="(item, key) in navs" :key="key" :name="item.component" icon-prefix="icon" :icon="item.icon">{{ item.name }}
			</van-tabbar-item>
		</van-tabbar>
		
		<el-dialog
			id="playerVideo"
			title="随机巡查"
			:visible.sync="playVideoDialog"
			:before-close="handlePlayVideoClose"
			:append-to-body="true"
			:destroy-on-close="true"
			:center="true"
			custom-class="player-video-dialog"
			width="100%" 
		>
			<div class="video-container" style="height: 80vh; position: relative;">
				<iframe
					id="ysOpenDevice"
					:src="iframeSrc"
					width="100%"
					height="100%"
					allowfullscreen
					frameborder="0"
				/>
				<div class="ptz-toggle" @click="togglePTZPanel">
					<i :class="['el-icon-arrow-left', {'is-active': showPTZPanel}]"></i>
				</div>
				<transition name="slide">
					<div v-show="showPTZPanel" class="ptz-controls">
						<div class="ptz-speed-control">
							<span class="speed-label">速度</span>
							<el-slider
								v-model="ptzSpeed"
								:min="1"
								:max="7"
								:step="1"
								size="small"
								style="width: 100%"
							></el-slider>
						</div>
						
						<div class="control-panel">
							<!-- PTZ Direction Controls -->
							<div class="direction-grid">
								<el-button class="grid-btn" size="mini" icon="el-icon-top-left" 
									:class="{'is-active': activeControl === 4}"
									@click="togglePTZ(4)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-top" 
									:class="{'is-active': activeControl === 0}"
									@click="togglePTZ(0)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-top-right" 
									:class="{'is-active': activeControl === 6}"
									@click="togglePTZ(6)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-back" 
									:class="{'is-active': activeControl === 2}"
									@click="togglePTZ(2)">
								</el-button>
								<el-button class="grid-btn center-btn" size="mini" icon="el-icon-video-pause" 
									@click="stopAllControls">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-right" 
									:class="{'is-active': activeControl === 3}"
									@click="togglePTZ(3)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-bottom-left" 
									:class="{'is-active': activeControl === 5}"
									@click="togglePTZ(5)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-bottom" 
									:class="{'is-active': activeControl === 1}"
									@click="togglePTZ(1)">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-bottom-right" 
									:class="{'is-active': activeControl === 7}"
									@click="togglePTZ(7)">
								</el-button>
							</div>

							<!-- Zoom Controls -->
							<div class="zoom-grid">
								<el-button class="grid-btn" size="mini" icon="el-icon-zoom-in" 
									:class="{'is-active': activeControl === 8}"
									@click="toggleZoom(0)">
								</el-button>
								<el-button class="grid-btn center-btn" size="mini" icon="el-icon-video-pause" 
									@click="stopAllControls">
								</el-button>
								<el-button class="grid-btn" size="mini" icon="el-icon-zoom-out" 
									:class="{'is-active': activeControl === 9}"
									@click="toggleZoom(1)">
								</el-button>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Login from '../login/index.vue'
	import Map from './map.vue'
	import Video from './video.vue'
	import Kaoqin from './kaoqin.vue'
	import { getQueryString } from '@/utils/utils'
	import { getIframeSrc, controlPTZ, stopPTZ, controlZoom, stopZoom } from '@/utils/video';
	export default {
		components: {
			Login,
			Map,
			Video,
			Kaoqin,
		},
		data() {
			return {
				component: 'Map',
				navs: [{
					icon: 'map',
					name: '地图',
					component: 'Map'
				}, {
					icon: 'kaoqin',
					name: '考勤',
					component: 'Kaoqin'
				}, {
					icon: 'camera',
					name: '摄像头',
					component: 'Video'
				}],
				urlValue:'',
				playVideoDialog: false,
				iframeSrc: '',
				playerId: '',
				playerTitle: '',
				accessToken: '',
				deviceSerial: '',
				ptzSpeed: 1,
				ptzControlling: false,
				activeControl: null,
				controlType: null, // 'ptz' or 'zoom'
				showPTZPanel: false,
			}
		},
		created() {
			var value = getQueryString('component')
			console.log(value)
			if(value != null && value != '') {
				this.component = value
				this.urlValue = value
			}
		},
		methods: {
			async showCameraDialog(type, data) {
				if (type === 'camera') {
					try {
						const { iframeSrc, deviceSerial, accessToken } = await getIframeSrc(data.videoUrl);
						
						if (!deviceSerial) {
							this.$message({
								type: 'error',
								message: '无法获取设备序列号'
							});
							return;
						}

						this.accessToken = accessToken;
						this.deviceSerial = deviceSerial;
						this.iframeSrc = iframeSrc;
						
						this.$nextTick(() => {
							this.playVideoDialog = true;
							this.playerId = data.cameraId;
							this.playerTitle = data.name;
						});

						// Log for debugging
						console.log('Device Serial:', this.deviceSerial);
						console.log('Access Token:', this.accessToken);
					} catch (error) {
						console.error('Error in showCameraDialog:', error);
						this.$message({
							type: 'error',
							message: '加载摄像头失败'
						});
					}
				}
				
				data.deviceStatus = 1; // 临时设置为全部在线
				if (data.deviceStatus !== 1) {
					this.$message({
						message: '摄像头不在线',
						type: 'warning'
					});
					return;
				}
			},
			handlePlayVideoClose() {
				this.playVideoDialog = false;
				this.iframeSrc = '';
				this.playerId = '';
				this.playerTitle = '';
			},
			canControlPTZ() {
				if (!this.deviceSerial || !this.accessToken) {
					this.$message({
						type: 'warning',
						message: '云台控制未就绪'
					});
					return false;
				}
				return true;
			},
			async togglePTZ(direction) {
				if (!this.canControlPTZ() || this.ptzControlling) return;
				
				this.ptzControlling = true;
				this.activeControl = direction;
				
				try {
					// Start motion
					const result = await controlPTZ(this.accessToken, this.deviceSerial, direction, this.ptzSpeed);
					if (!result.success) {
						this.$message({
							type: 'error',
							message: result.msg
						});
						return;
					}
					
					// Wait a short duration (e.g., 500ms)
					await new Promise(resolve => setTimeout(resolve, 250));
					
					// Automatically stop
					await stopPTZ(this.accessToken, this.deviceSerial);
					
				} catch (error) {
					console.error('PTZ control error:', error);
					this.$message({
						type: 'error',
						message: '云台控制失败'
					});
				} finally {
					this.activeControl = null;
					this.ptzControlling = false;
				}
			},
			async toggleZoom(operation) {
				if (!this.canControlPTZ() || this.ptzControlling) return;
				
				const zoomDirection = operation === 0 ? 8 : 9;
				this.ptzControlling = true;
				this.activeControl = zoomDirection;
				
				try {
					// Start zoom
					const result = await controlZoom(this.accessToken, this.deviceSerial, operation, this.ptzSpeed);
					if (!result.success) {
						this.$message({
							type: 'error',
							message: result.msg
						});
						return;
					}
					
					// Wait a short duration (e.g., 500ms)
					await new Promise(resolve => setTimeout(resolve, 250));
					
					// Automatically stop
					await stopZoom(this.accessToken, this.deviceSerial);
					
				} catch (error) {
					console.error('Zoom control error:', error);
					this.$message({
						type: 'error',
						message: '变焦控制失败'
					});
				} finally {
					this.activeControl = null;
					this.ptzControlling = false;
				}
			},
			async stopAllControls() {
				if (this.ptzControlling) {
					await stopPTZ(this.accessToken, this.deviceSerial);
					await stopZoom(this.accessToken, this.deviceSerial);
					this.activeControl = null;
					this.ptzControlling = false;
				}
			},
			togglePTZPanel() {
				this.showPTZPanel = !this.showPTZPanel;
			},
		}
	}
</script>
<style>
	.container {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	
	.component-container {
		width: 100%;
		height: calc(100%);
		overflow: auto;
	}
	.component-container-full {
		width: 100%;
		height: 100%;
		overflow: auto;
	}
	.el-dialog--center .el-dialog__body{
		padding: 5px 0px 5px 0px
	}

	.video-container {
		position: relative;
	}

	.ptz-controls {
		position: absolute;
		right: 0;
		bottom: 20px;
		background: rgba(0, 0, 0, 0.6);
		padding: 15px;
		border-radius: 4px 0 0 4px;
		width: 240px;
		box-sizing: border-box;
		z-index: 999;
	}

	.ptz-speed-control {
		margin-bottom: 15px;
		padding: 0 6px;
	}

	.speed-label {
		color: white;
		font-size: 12px;
		margin-right: 8px;
	}

	.control-panel {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
	}

	.direction-grid {
		display: grid;
		grid-template-columns: 60px 60px 60px;
		grid-template-rows: 36px 36px 36px;
		gap: 12px;
		justify-content: center;
		margin-bottom: 12px;
		width: 100%;
	}

	.zoom-grid {
		display: grid;
		grid-template-columns: 60px 60px 60px;
		grid-template-rows: 36px;
		gap: 12px;
		justify-content: center;
		width: 100%;
	}

	.grid-btn {
		width: 60px !important;
		height: 36px !important;
		padding: 0 !important;
		margin: 0 !important;
		border: none !important;
		background: white !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		border-radius: 4px !important;
	}

	.el-button.grid-btn {
		width: 60px !important;
		height: 36px !important;
	}

	.grid-btn:hover {
		background: #409EFF !important;
		color: white !important;
	}

	.center-btn {
		background: #F2F6FC !important;
	}

	.el-slider {
		margin: 0;
		flex: 1;
	}

	.el-slider__runway {
		height: 4px;
		margin: 12px 0;
	}

	.el-slider__button {
		width: 12px;
		height: 12px;
	}

	.el-slider__bar {
		background-color: #409EFF;
		height: 4px;
	}

	/* Adjust dialog padding */
	.player-video-dialog .el-dialog__body {
		padding: 5px 0px;
	}

	.grid-btn.is-active {
		background-color: #409EFF !important;
		color: white !important;
	}

	.ptz-toggle {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background: rgba(0, 0, 0, 0.6);
		padding: 15px 5px;
		border-radius: 4px 0 0 4px;
		cursor: pointer;
		z-index: 1000;
		transition: all 0.3s ease;
	}

	.ptz-toggle:hover {
		background: rgba(0, 0, 0, 0.8);
	}

	.ptz-toggle i {
		color: white;
		font-size: 20px;
		transition: transform 0.3s ease;
	}

	.ptz-toggle i.is-active {
		transform: rotate(180deg);
	}

	/* Add slide transition */
	.slide-enter-active,
	.slide-leave-active {
		transition: transform 0.3s ease;
	}

	.slide-enter,
	.slide-leave-to {
		transform: translateX(100%);
	}

	.slide-enter-to,
	.slide-leave {
		transform: translateX(0);
	}

</style>