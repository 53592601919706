import axios from 'axios'
import request from '@/utils/request'

export function markerList() {
	// const data = require("@/json/markers.json")
	// return data
	return request({
		url: 'marker/list',
		method: 'post'
	})
}

export function anchorList() {
//	const data = require("@/json/anchors.json")
//	return data
  return request({
    url: 'anchor/list',
    method: 'post'
  })
}

export function getShipByRect(bbox, zoom) {
	return axios({
		url: 'http://www.shipzz.com/api/position/bbox?bbox=' + bbox + '&zoom=' + zoom,
		method: 'get'
	})
}
export function getAisPositions(extent) {
	let params = '';
	if(extent) {
		extent = extent.map(it => parseInt(Math.round(it * 600000)))
		params = '?xmin=' + extent[0] + '&ymin=' + extent[1] + '&xmax=' + extent[2] + '&ymax=' + extent[3]
	}
	return request({
		url: 'ais/positions' + params,
		method: 'get'
	})
}

export function getShipAllPersonnelStatus(shipId, inputDate) {
	return request({
		url: 'personnel/getShipAllPersonnelStatus',
		method: 'post',
		params: {
			shipId: shipId,
			inputDate: inputDate
		}
	})
}

export function getAisHistory(mmsi,start,end,devices){
	return request({
		url: `/ais/position/history1?mmsi=${mmsi}&startTime=${start}&endTime=${end}&devices=${(devices||[]).join(',')}`,
		method: 'get'
	})

}

export function getDevicePos() {
	return request({
		url: 'custom/ship/list',
		method: 'get'
	})
}

export function getCameraList() {
	return request({
		url: '/camera/list',
		method: 'get',
	})
}

export function getShipInfo(type) {
	return request({
		url: '/ship/info/list',
		method: 'get',
		params: {
			type: type
		}
	})
}

export function getShipPos(mmsis) {
	return request({
		url: '/ship/pos',
		method: 'get',
		params: {
			mmsis
		}
	})
}

export function getCustomShipList() {
	return request({
		url: 'custom/ship/list',
		method: 'get'
	})
}

export function alarmList(current) {
	return request({
		url: 'screen/alarm?current=' + (current || 1),
		method: 'get',
	})
}



export function getBeachRiskList() {
  return request({
    url: '/progress/getBeachRiskList',
    method: 'get'
  })
}

export function sailChannelNoticsList(data) {
  return request({
    url: 'sailChannelNotics/list',
    method: 'post',
    params: data
  })
}

export function maintenanceScaleList(data) {
  return request({
    url: 'maintenanceScale/list',
    method: 'post',
    params: data
  })
}

export function shipList(data) {
  return request({
    url: 'ship/list',
    method: 'post',
    params: data
  })
}

export function contingencyManagementList(data) {
  return request({
    url: 'contingencyManagement/list',
    method: 'post',
    params: data
  })
}

export function emergencyMisposalList(data) {
  return request({
    url: 'emergencyMisposal/list',
    method: 'post',
    params: data
  })
}

export function safetyWarningList(data) {
  return request({
    url: 'safetyWarning/list',
    method: 'post',
    params: data
  })
}


export function getShipDailyList(shipId) {
	return request({
		url: 'shipDaily/shipDailyList',
		method: 'get',
		params: {
			shipId: shipId
		}
	})
}


export function enclosureList() {
  return request({
    url: 'enclosure/list',
    method: 'post'
  })
}
