<template>
    <div>
      <van-cell title="姓名" :value="$store.state.user.name" size="large" />
      <van-cell title="日期" :value="createDate" size="large" />
      <van-cell title="打卡地点" :value="address" size="large" />
      <van-cell title="打卡时间" :value="createTime" size="large" />
      <div class="kaoqin" @click="doRecord">
        <span>{{ nowTime }}<br>打卡</span>
      </div>
    </div>
</template>
<script>
import {formatDate} from '@/utils/utils'
import {Toast} from "vant";
export default {
  name: 'Kaoqin',
  data(){
    return {
      lng: null,
      lat: null,
      createDate: formatDate(new Date(),'yyyy-MM-dd'),
      address: '地址获取中',
      createTime: '暂未打卡',
      nowTime : null
    }
  },
  created(){
    this.init();
  },
  methods: {
    updateTime(){
      this.nowTime = formatDate(new Date(), 'hh:mm:ss')
    },
    init(){
      setInterval(this.updateTime,1000)
      this.updateTime()
      this.$get('/app/attendance/record').then((res)=>{
        let data = res.data;
        if(data&&!data.endTime){
          this.address = data.startAddress;
          this.createTime = data.startTime;
        }else{
          this.address = '地址获取中';
          this.createTime = '暂未打卡'
        }

        let amap = window.AMap;
        amap.plugin('AMap.Geolocation', ()=>{
          let geolocation = new amap.Geolocation({
            enableHighAccuracy: true,
            timeout: 5000,
          })
          geolocation.getCurrentPosition()
          amap.event.addListener(geolocation,'complete',(data)=>{
            this.lng = data.position.lng;
            this.lat = data.position.lat;
            this.address = data.formattedAddress || '获取地址失败'
            console.log(data.formattedAddress)
          })
          amap.event.addListener(geolocation,'complete',(data)=>{
            console.log(data)
            this.address = data.formattedAddress || '获取地址失败'
          })
        })
      })
    },
    doRecord(){
      if(this.lng&& this.lat){
        this.$post('/app/attendance/process',{
          lon: this.lng,
          lat: this.lat,
          address: this.address
        }).then(()  => {
          Toast({
            message: '打卡成功',
            duration: 3000
          })
          this.init();
        })
      }else{
        Toast({
          message: '获取定位失败',
          duration: 3000
        })
      }
    }
  }
}
</script>
<style scoped>
.kaoqin{
  width: 6rem;
  height: 6rem;
  margin-left: 2rem;
  border-radius: 50%;
  background: rgb(8, 118, 231);
  margin-top: 1rem;
}
.kaoqin span{
  color: #fff;
  text-align: center;
  display: inline-block;
  line-height: 1rem;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 2rem;
}
</style>