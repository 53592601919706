export function extractDeviceSerial(videoUrl) {
  try {
    // Example URL format: ezopen://open.ys7.com/[deviceSerial]/1.hd.live
    const match = videoUrl.match(/ezopen:\/\/[^/]+\/([^/]+)\//)
    return match ? match[1] : null
  } catch (error) {
    console.error('Error extracting deviceSerial:', error)
    return null
  }
}

export async function getIframeSrc(url) {
    try {
      const response = await fetch('https://open.ys7.com/api/lapp/token/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'appKey=7162e8bcf0734f82affcd9f965e421ec&appSecret=948b482c297125a2e7470fb7f7196b50'
      })
      const data = await response.json()
      const accessToken = data.data.accessToken
      const deviceSerial = extractDeviceSerial(url)
  
      return {
        iframeSrc: `https://open.ys7.com/ezopen/h5/iframe?url=${encodeURIComponent(url)}&autoplay=1&audio=1&accessToken=${accessToken}`,
        deviceSerial,
        accessToken
      }
    } catch (error) {
      console.error('Error obtaining access token:', error)
      return {
        iframeSrc: '',
        deviceSerial: null,
        accessToken: null
      }
    }
}

export async function controlPTZ(accessToken, deviceSerial, direction, speed = 1) {
  try {
    // Direction codes according to API:
    // 0-up, 1-down, 2-left, 3-right, 4-left-up, 5-left-down, 6-right-up, 7-right-down
    // Speed range: 0-7 (API specifies 1-7 but we'll constrain it)
    const constrainedSpeed = Math.max(1, Math.min(7, speed));
    
    const response = await fetch('https://open.ys7.com/api/lapp/device/ptz/start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        accessToken,
        deviceSerial,
        channelNo: 1,
        direction,
        speed: constrainedSpeed
      })
    });
    
    const data = await response.json();
    if (data.code === '200') {
      return { success: true, msg: '云台控制成功' };
    } else {
      return { success: false, msg: data.msg || '云台控制失败' };
    }
  } catch (error) {
    console.error('Error controlling PTZ:', error);
    return { success: false, msg: '云台控制请求失败' };
  }
}

export async function stopPTZ(accessToken, deviceSerial) {
  try {
    const response = await fetch('https://open.ys7.com/api/lapp/device/ptz/stop', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        accessToken,
        deviceSerial,
        channelNo: 1
      })
    });
    
    const data = await response.json();
    if (data.code === '200') {
      return { success: true, msg: '云台停止成功' };
    } else {
      return { success: false, msg: data.msg || '云台停止失败' };
    }
  } catch (error) {
    console.error('Error stopping PTZ:', error);
    return { success: false, msg: '云台停止请求失败' };
  }
}

export async function controlZoom(accessToken, deviceSerial, operation, speed = 1) {
  try {
    // Operation: 0-放大(ZOOM_IN), 1-缩小(ZOOM_OUT)
    const constrainedSpeed = Math.max(1, Math.min(7, speed));
    
    const response = await fetch('https://open.ys7.com/api/lapp/device/ptz/start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        accessToken,
        deviceSerial,
        channelNo: 1,
        direction: operation === 0 ? 8 : 9,
        speed: constrainedSpeed
      })
    });
    
    const data = await response.json();
    if (data.code === '200') {
      return { success: true, msg: '变焦控制成功' };
    } else {
      return { success: false, msg: data.msg || '变焦控制失败' };
    }
  } catch (error) {
    console.error('Error controlling zoom:', error);
    return { success: false, msg: '变焦控制请求失败' };
  }
}

export async function stopZoom(accessToken, deviceSerial) {
  try {
    const response = await fetch('https://open.ys7.com/api/lapp/device/ptz/stop', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        accessToken,
        deviceSerial,
        channelNo: 1
      })
    });
    
    const data = await response.json();
    if (data.code === '200') {
      return { success: true, msg: '变焦停止成功' };
    } else {
      return { success: false, msg: data.msg || '变焦停止失败' };
    }
  } catch (error) {
    console.error('Error stopping zoom:', error);
    return { success: false, msg: '变焦停止请求失败' };
  }
}