<template>
	<div>
		<van-nav-bar :style="urlValue == '' ? '':{'margin-top':'30px'}" :title="title" :left-text="returnText" :left-arrow="returnText !== ''" @click-left="returnList" />
		<van-list v-if="!showVideo" v-model="loading" finished-text="没有更多了" :finished="finished" @load="onLoad">
			<van-cell v-for="item in videos" :key="item.id" :title="item.name" @click="()=>onClick(item)" :value="item.deviceStatus === 1 ? '在线': '离线'"></van-cell>
		</van-list>
		<div v-if="showVideo" class="video-container">
			<canvas ref="video" id="video"></canvas>
			<div class="iconfont icon-zoom-in" @click="sendCommand(11)"></div>
			<div class="iconfont icon-zoom-out" @click="sendCommand(12)"></div>
			<ul class="control-container">
				<li v-for="(item,i) in commands" :key="i">
					<div><span @click="sendCommand(item)"></span></div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import { Base64 } from '@/utils/base64'
	import JSMpeg from "@/assets/video/jsmpeg/jsmpeg";
	import { getQueryString } from '@/utils/utils'
	export default {
		name: 'Video',
		data() {
			return {
				loading: true,
				finished: false,
				videos: [],
				returnText: '',
				title: '摄像头',
				videoId: null,
				showVideo: false,
				commands: [23, 25, 21, 26, 24, 28, 22, 27],
				player: null,
				websocket: null,
				urlValue: ''
			}
		},
		created() {
			var value = getQueryString('component')
			if(value != null && value != '') {
				this.urlValue = value
			}
			this.onLoad();
		},
		beforeDestroy() {
			this.stop()
		},
		methods: {
			stop() {
				console.log('stop')
				try {
					this.player.destroy()
				} catch(e) {
					console.log(e)
				}
			},
			async onLoad() {
				this.videos = this.$get('camera/list').then(res => {
					this.videos = res.data
					this.loading = false;
					this.finished = true
				})
			},
			returnList() {
				this.showVideo = false;
				this.title = '摄像头';
				this.returnText = '';
				this.stop()
			},
			sendCommand(command) {
				console.log(command)
				try {
					fetch('http://frp.shipzz.com:7111/video/control?deviceId=' + this.videoId + '&operator=' + command)
				} catch(e) {
					console.log(e)
				}
			},
			onClick(item) {
				this.showVideo = true;
				this.title = item.name;
				this.returnText = '返回'
				this.videoId = item.id;
				this.$nextTick(() => {
					let url = process.env.VUE_APP_VIDEO_WEBSOCKET_SERVER + `/mpeg/${Base64.encodeURL(item.videoUrl)}`
					this.player = new JSMpeg.Player(url, {
						canvas: this.$refs.video,
						onPlay: function() {
							console.log('load');
						}
					});
				})
			}
		}
	}
</script>
<style scoped>
	.video-container {
		width: 100%;
		height: calc((100% - 50px));
	}
	
	#video {
		width: 100%;
		height: 50%;
	}
	
	.video-container .control-container {
		width: 5rem;
		height: 5rem;
		margin: 0 auto;
		background-image: url(../../assets/control_bg.png);
		background-size: 100% 100%;
		background-position: center center;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
	}
	
	.video-container .control-container li {
		list-style: none;
		border: 1px solid transparent;
		transform: rotate(-22.5deg) skew(45deg);
		transform-origin: 100% 100%;
		position: absolute;
		width: 50%;
		height: 50%;
		overflow: hidden;
	}
	
	.video-container .control-container li div {
		position: absolute;
		width: 200%;
		height: 200%;
		line-height: 200%;
		top: 0;
		left: 0;
		border-radius: 50%;
		text-align: center;
		transform: skew(-45deg) rotate(-67.5deg);
		cursor: default;
	}
	
	.video-container .control-container li div span {
		background-image: url(../../assets/up.png);
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		width: 0.8rem;
		height: 1.2rem;
	}
	
	.video-container .control-container li:nth-child(1) {
		transform: rotate(-22.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(2) {
		transform: rotate(22.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(3) {
		transform: rotate(67.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(4) {
		transform: rotate(112.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(5) {
		transform: rotate(157.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(6) {
		transform: rotate(-157.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(7) {
		transform: rotate(-112.5deg) skew(45deg);
	}
	
	.video-container .control-container li:nth-child(8) {
		transform: rotate(-67.5deg) skew(45deg);
	}
	
	.icon-zoom-in,
	.icon-zoom-out {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.8rem;
		font-weight: bold;
		color: #666;
		margin-left: 2rem;
		text-align: center;
	}
	
	.icon-zoom-out {
		margin-left: 4rem;
	}
</style>