import { Fill, Stroke, Style, Text, Icon} from 'ol/style'
import {  GeometryCollection, LineString, Polygon } from 'ol/geom'
// import { getPointResolution, get as getProj } from 'ol/proj';
const degreeAbs = (val) => {
  val = Math.abs(val);
  if(val > 180) {
    return 360 - val;
  } else {
    return val;
  }
}
const getAngle = (heading, speed, course) => {
  if(heading > 0 && heading < 360) {
    if(speed >= 3 && degreeAbs(heading - course) >= 15) {
      if(course >= 0 && course < 360) {
        return course;
      } else {
        return heading;
      }
    } else {
      return heading;
    }
  } else if(course >= 0 && course < 360) {
    return course;
  }
  return 0;
}
/*const epsg3857 = getProj('EPSG:3857')*/
export default function getShipStyle(map, center, shipWidth, shipLength, speed, heading, course, postime, text, color, selected, res){
  let styles = [];
  let rw;
  let rh;
  let canDrawReal = false;
  shipWidth = (shipWidth || 0)
  shipLength = (shipLength || 0)
  //判断可否按比例画船
  /*if(shipWidth&&shipLength&&shipLength <500){
    //获取一像素单位
    let meter = getPointResolution(epsg3857, res, center) * epsg3857.getMetersPerUnit();
    rh = shipLength/meter;
    rw = shipWidth/meter;
    let ratio = rh/rw;
    canDrawReal = meter>0&&rw>3&&rh>25&&ratio>2&&ratio<20;
  }*/
  if(!Array.isArray(text) && postime < new Date().getTime() / 1000 - 7200 && !selected) {
    return null;
  }
  styles.push(new Style({
    fill: new Fill({
      color: color || (postime > new Date().getTime() / 1000 - 1800 ? '#baff00' : '#00ff00')
    }),
    stroke: new Stroke({
      color: 'black'
    }),
    zIndex: selected ? 1 : 0,
    geometry: () =>{
      let pixel = map.getPixelFromCoordinate(center)
      let w = canDrawReal ? rw : 12;
      let h = canDrawReal ? rh : 20;
      let transform = (array)=>array.map(it => {
        return map.getCoordinateFromPixel([it[0] - w /2 + pixel[0], it[1] - h / 2 + pixel[1]])
      })
      let geoms = [new Polygon([
        transform(
          canDrawReal ? [[w/2,0],[w*2/7,h/18],[0.5,h/6],[0.5,h*14/15],[w/6,h],[w*5/6,h],[w,h*14/15],[w,h/6],[w*5/7,h/18],[w/2,0]] : [[w/2,0],[0,h],[w,h],[w/2,0]]
        )
      ])];
      if(speed > 1){
        let len = Math.min(Math.max(5, parseInt(speed) * 3), canDrawReal ? 40 : 20)
        geoms.push(new LineString(transform([
          [w / 2, 0],
          [w / 2, -len]
        ])))
      }
      var collection = new GeometryCollection(geoms);
      if (speed!==0){
        collection.rotate(-course * Math.PI / 180, center)
        console.log(-course * Math.PI / 180, center)
      }else{
        collection.rotate(-getAngle(heading, speed, course) * Math.PI / 180, center)
        console.log(-getAngle(heading, speed, course) * Math.PI / 180)
      }
      return collection;
    }
  }));
  if(Array.isArray(text)){
    text.forEach(it => styles.push(it));
  }else if(res < 7.88 / 4 || selected){
    styles.push(new Style({
      text: new Text({
        offsetX: 20,
        offsetY: -20,
        text: text,
        textAlign: 'left',
        backgroundFill: new Fill({
          color: [255, 255, 255, .8]
        }),
        backgroundStroke: new Stroke({
          color: [213, 228, 252, 1],
          width: 1
        }),
        padding: [4, 3, 2, 6],
        font: '12px sans-serif'
      }),
      zIndex: 4
    }))
  }
  if (selected) {
    styles.push(new Style({
      image: new Icon({
        src: require('@/assets/images/map/select.png'),
        scale: 1,
        rotation: -getAngle(heading, speed, course) * Math.PI / 180
      }),
      zIndex: 2
    }))
  }
  return styles;
}
