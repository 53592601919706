import { Vector as VectorSource} from 'ol/source'
import { Vector as VectorLayer} from 'ol/layer'
import { Style, Fill, Text, Stroke, Circle} from 'ol/style'
import { Feature } from 'ol'
import { Point, Polygon } from 'ol/geom'
import { anchorList } from "@/api/map.js";
const source = new VectorSource();
const data = anchorList();
let features = [];
for (let i = 0; i < data.length; i++) {
    let item = data[i];
    let geometry;
    let geojson = JSON.parse(item.geojson)[0];
    let style;
    if (geojson.type === 'point') {
        geometry = new Point(geojson.point)
        style = new Style({
            image: new Circle({
                radius: 4,
                snapToPixel: false,
                fill: new Fill({
                    color: '#FF0F0F'
                }),
                stroke: new Stroke({
                    color: '#FF0F0F',
                    width: 4
                })
            }),
            text: new Text({
                text: item.name,
                textBaseline: 'top',
                offsetY: 5
            })
        });
    } else {
        let coords = [];
        for (let j = 0, len = geojson.points.length; j < len; j += 2) {
            coords.push([
                geojson.points[j],
                geojson.points[j + 1]
            ]);
        }
        geometry = new Polygon([coords]);
        style = new Style({
            fill: new Fill({
                color: 'rgba(255, 78, 78, 0.2)'
            }),
            stroke: new Stroke({
                color: '#FF0F0F',
                width: 1.5
            }),
            text: new Text({
                text: item.name,
                textBaseline: 'top',
                offsetY: 5
            })
        });
    }
    let feature = new Feature({
        geometry: geometry.transform("EPSG:4326", "EPSG:3857")
    })
    feature.setStyle(style);
    features.push(feature);
}
source.addFeatures(features);
export default new VectorLayer({ source })