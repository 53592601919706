import { enclosureList } from "@/api/map.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Circle as CircleStyle, Style, Text, Fill, Stroke } from 'ol/style'
import { Feature } from 'ol'
import { Polygon } from 'ol/geom'

const source = new VectorSource();

enclosureList().then(res => {
	const { data } = res;
	
	if (data && Array.isArray(data) && data.length > 0) {
		let features = [];
		for(let i = 0; i < data.length; i++) {
			let enclosure = data[i];
			if (enclosure && enclosure.coordinates) {
				try {
					let geometry = new Polygon([JSON.parse(enclosure.coordinates)]);
					let feature = new Feature({
						geometry: geometry.transform("EPSG:4326", "EPSG:3857")
					});
					feature.setId(enclosure.id);
					feature.set('type', 'enclosure');
					feature.set('data', enclosure);
					features.push(feature);
				} catch (error) {
					console.error(`Error processing enclosure ${enclosure.id}:`, error);
				}
			}
		}
		source.addFeatures(features);
	} else {
		console.warn('No valid enclosure data received');
	}
}).catch(error => {
	console.error('Error fetching enclosure data:', error);
});

export default new VectorLayer({
	source: source,
	declutter: true,
	maxResolution: 150,
	style: (feature) => {
		var data = feature.get('data');
		return new Style({
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.7)',
			}),
			stroke: new Stroke({
				color: 'red',
				width: 2
			}),
			text: new Text({
				text: data.name,
				font: '14px sans-serif',
				fill: new Fill({
					color: 'red'
				}),
				offsetY: -15,
				stroke: new Stroke({
					color: 'white',
					width: 5
				})
			}),
			image: new CircleStyle({
				radius: 7,
				fill: new Fill({
					color: 'red'
				})
			})
		})
	}
})