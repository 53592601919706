import { getCustomAreas } from "@/api/area.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Circle as CircleStyle, Style, Icon, Text, Fill, Stroke } from 'ol/style'
import { Feature } from 'ol'
import { Point, Polygon, Circle, LineString } from 'ol/geom'
const source = new VectorSource();
getCustomAreas().then(res => {
	const {
		data
	} = res
	let features = [];
	for(let i = 0; i < data.length; i++) {
		let area = data[i]
		let geometry = new Polygon([JSON.parse(area.coordinates)]);
		if(area.areaType === 'LineString') {
			geometry = new LineString(JSON.parse(area.coordinates));
		} else if(area.areaType === 'Circle') {
			let coordinates = JSON.parse(area.coordinates);
			geometry = new Circle([coordinates[0], coordinates[1]], coordinates[2]);
		} else if(area.areaType === 'Point') {
			geometry = new Point(JSON.parse(area.coordinates));
		}
		let feature = new Feature({
			geometry: geometry.transform("EPSG:4326", "EPSG:3857")
		})
		feature.setId(area.id);
		feature.set('type', 'customArea')
		feature.set('data', area);
		features.push(feature);
	}
	source.addFeatures(features);
})
export default new VectorLayer({
	source: source,
	declutter: true,
	maxResolution: 150,
	style: (feature) => {
		let data = feature.get('data')
		var styles = []
		if(data.icon) {
			var icon = new Icon({
				src: data.icon,
				scale: 1
			})
			if(data.selected) {
				icon = new Icon({
					src: data.icon,
					scale: 1,
					color: '#00FFFF'
				})
			}
			styles.push(new Style({
				text: new Text({
					text: data.name,
					font: '14px sans-serif',
					fill: new Fill({
						color: data.textStyle
					}),
					offsetY: -(data.iconHeight / 2 + 15),
					stroke: new Stroke({
						color: data.textStrokeStyle,
						width: 5
					})
				}),
				image: icon
			}))
		} else {
			styles.push(new Style({
				stroke: new Stroke({
					color: data.strokeStyle
				}),
				fill: new Fill({
					color: data.fillStyle
				}),
				text: new Text({
					text: data.name,
					font: '14px sans-serif',
					fill: new Fill({
						color: data.textStyle
					}),
					offsetY: -15,
					stroke: new Stroke({
						color: data.textStrokeStyle,
						width: 5
					})
				}),
				image: new CircleStyle({
					fill: new Fill({
						color: data.fillStyle
					}),
					stroke: new Stroke({
						color: data.strokeStyle
					}),
					radius: 5
				})
			}))
		}
		console.log("area")
		if(data.selected) {
			styles.push(new Style({
				stroke: new Stroke({
					color: '#00FFFF',
					width: 5
				}),
				image: new CircleStyle({
					stroke: new Stroke({
						color: '#00FFFF'
					}),
					radius: 6
				})
			}))
			if(data.areaType == 'Point') {
				styles.push(new Style({
					fill: new Fill({
						color: '#00FFFF'
					}),
					image: new CircleStyle({
						fill: new Fill({
							color: '#00FFFF'
						}),
						stroke: new Stroke({
							color: '#00FFFF'
						}),
						radius: 5
					})
				}))
			}
		}
		return styles
	}
})