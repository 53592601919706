import { Group, Tile } from 'ol/layer'
import { XYZ } from 'ol/source'

export default new Group({
    layers: [new Tile({
        source: new XYZ({
            url: 'https://t{0-3}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=a5ccddf1da15b533ec7e38b719fb878f',
            projection: 'EPSG:3857'
        }),
        isGroup: true,
        name: '影像底图'
    }),
    new Tile({
        source: new XYZ({
            url: 'https://t{0-3}.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=a5ccddf1da15b533ec7e38b719fb878f',
            projection: 'EPSG:3857'
        }),
        isGroup: true,
        name: '地形注记'
    })]
})