import { waterSiteList, waterLevelList, getUpperReachesWatersites, getUserRiverCollections, saveUserRiverCollection, getWaterSiteList } from "@/api/watersite.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Icon, Text } from 'ol/style'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { formatDate } from '@/utils/utils'

const source = new VectorSource();
export const loadWatersites = () => {
	waterSiteList().then(res => {
		const {
			data
		} = res
		let features = [];
		for(let i = 0; i < data.length; i++) {
			let item = data[i];
			let geometry = new Point(JSON.parse(item.coordinates));
			let feature = new Feature({
				geometry: geometry.transform("EPSG:4326", "EPSG:3857")
			})
			feature.setStyle(new Style({
				image: new Icon({
					scale: 0.8,
					src: require('@/assets/images/map/waterSite.png')
				}),
				text: new Text({
					text: item.waterSite,
					textBaseline: 'top',
					offsetY: 18
				})
			}))
			feature.setId(item.id);
			feature.set('data', item);
			feature.set('type', 'watersite');
			features.push(feature);
		}
		source.addFeatures(features);
	})
}
var currentDate = new Date();

export const getWaterLevelList = (watersite, obj) => {
	var d = {
		siteName: watersite.waterSite,
		day: -7
	}
	waterLevelList(d).then(res => {
		const {
			data
		} = res
		obj.selectDetail = data
	})
}

/**
 * 获取今天昨天去年历史水位
 */
export const getWaterLevelHistory = (watersite, obj) => {
	var date = new Date(JSON.parse(JSON.stringify(currentDate)))
	var currentdayQueryData = {
		siteName: watersite.waterSite,
		date: formatDate(date, 'yyyy-MM-dd')
	}
	waterLevelList(currentdayQueryData).then(res => {
		const {
			data
		} = res
		if(data.length > 0) {
			obj.currentdayWatersite = data[0].level
		}
	})
	var yesterdayQueryData = {
		siteName: watersite.waterSite,
		date: formatDate(date.setDate(date.getDate() - 1), 'yyyy-MM-dd')
	}
	waterLevelList(yesterdayQueryData).then(res => {
		const {
			data
		} = res
		if(data.length > 0) {
			obj.yesterdayWatersite = data[0].level
		}
	})
	var lastyeardayQueryData = {
		siteName: watersite.waterSite,
		date: formatDate(date.setFullYear(date.getFullYear() - 1), 'yyyy-MM-dd')
	}
	waterLevelList(lastyeardayQueryData).then(res => {
		const {
			data
		} = res
		if(data.length > 0) {
			obj.lastyeardayWatersite = data[0].level
		}
	})
}

/**
 * 获取上游
 */
export const getUpperReachesData = async(watersite, obj) => {
	var resData = await getUpperReachesWatersites(watersite.waterSite)
	var siteNameList = []
	if(resData.data.length > 0) {
		for(var data of resData.data) {
			siteNameList.push(data.displayName)
		}
	}
	console.log('getUpperReachesData')
	if(siteNameList.length > 0) {
		var queryData = {
			siteNames: siteNameList.join(),
			date: formatDate(currentDate, 'yyyy-MM-dd')
		}
		waterLevelList(queryData).then(res => {
			const {
				data
			} = res
			//console.log("resData")
			if(data.length > 0) {
				obj.siteNameList = siteNameList
				var levelList = []
				for(var siteName of siteNameList) {
					var isAdd = false
					for(var d of data) {
						if(siteName == d.sitename) {
							levelList.push(d.level)
							isAdd = true
						}
					}
					if(!isAdd) {
						levelList.push(0)
					}
				}
				obj.levelList = levelList
			}
			obj.initUpperReachesChart()
		})
	}
}

/**
 * 获取历史水位
 */
export const getHistoryData = async(watersite, obj) => {
	var historyYearList = []
	historyYearList.push(currentDate.getFullYear())
	historyYearList.push(currentDate.getFullYear() - 1)
	historyYearList.push(currentDate.getFullYear() - 2)
	historyYearList.push(currentDate.getFullYear() - 3)
	historyYearList.push(currentDate.getFullYear() - 4)
	obj.historyYearList = historyYearList
	var historyLevelList = []
	for(var year of historyYearList) {
		var queryData = {
			siteName: watersite.waterSite,
			date: year + "-" + formatDate(currentDate, 'MM-dd')
		}
		var resData = await waterLevelList(queryData)
		if(resData.data.length > 0) {
			historyLevelList.push(resData.data[0].level)
		} else {
			historyLevelList.push(0)
		}
	}
	obj.historyLevelList = historyLevelList
	obj.initHistoryChart();
}

/**
 * 获取收藏
 */
export const getUserRiverCollectionData = async(watersite, obj) => {
	var resData = await getUserRiverCollections(watersite.waterSite)
	var userRiverCollection = ''
	var userRiverCollectionList = []
	if(resData.data.length > 0) {
		for(var d of resData.data) {
			userRiverCollection += d.siteName + ','
			userRiverCollectionList.push(d.siteName)
		}
	}
	obj.userRiverCollection = userRiverCollection
	obj.userRiverCollectionList = userRiverCollectionList
	console.log('getUserRiverCollectionData'+obj.userRiverCollection)
}

export const saveUserRiverCollectionData = async(watersite, obj) => {
	await saveUserRiverCollection(watersite.waterSite)
	getUserRiverCollectionData(watersite, obj)
}


export const getWaterSiteData = (waterSite, startDate, endDate) => {
	return new Promise((resolve, reject) => {
		getWaterSiteList(waterSite, startDate, endDate).then(res => {
			resolve(res);
		}).catch(error => {
			reject(error);
		})
	})
}
  

export const layer = () => {
	return new VectorLayer({
		source: source,
		declutter: true,
		maxResolution: 100
	})
};
