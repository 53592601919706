<template>
  <div class="project-details-popup">
    <div class="detail-header">
      <div class="header-name">{{ project.label }}</div>
      <div class="more" @click="$emit('close')">关闭</div>
    </div>
    <div class="detail-content" v-if="project">
      <el-row type="flex" style="width: 100%" class="summary-row">
        <el-col :span="8">
          <div class="total-item">
            <div class="progress-value">{{ formatAmount(project.value5) }}万元</div>
            <div class="progress-label">合同额</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="total-item">
            <div class="progress-value">{{ formatAmount(project.value6) }}万元</div>
            <div class="progress-label">工程完成</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="pie-chart-container" @click="toggleChartView">
            <div class="pie-chart" :class="{ 'half-view': isHalfView }">
              <Chart :data="getPieChartData()" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="summary" v-if="projectDetails.length">
      <div class="summary-row">
        <div class="summary-title">单位工程完成情况</div>
      </div>
      <div class="summary-row">
        <div class="summary-stats">
          单位工程总数: {{ totalProjects }}个， 已交工: {{ completedProjects }}个， 未交工: {{ incompleteProjects }}个
        </div>
      </div>
    </div>
    <table class="detail-table" v-if="projectDetails.length">
      <thead>
        <tr>
          <th>序号</th>
          <th>单位工程</th>
          <th>状态</th>
          <th>进展</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in projectDetails" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.label }}</td>
          <td>{{ item.progressStatus === 1 ? '已交工' : '未交工' }}</td>
          <td>{{ item.progressDesc || '未开展' }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="!projectDetails.length" class="loading">
      暂无项目详情...
    </div>
  </div>
</template>

<script>
import Chart from '@/components/chart.vue'
import { getOwnerProjectDetails } from '@/views/index/map/owner-projects-layer'

export default {
  components: {
    Chart
  },
  name: 'ProjectDetails',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      projectDetails: [],
      isHalfView: false
    }
  },
  computed: {
    completionPercentage() {
      const contractAmount = parseFloat(this.project.value5);
      const completedAmount = parseFloat(this.project.value6);
      if (!contractAmount || contractAmount === 0) return 0;
      return parseFloat((completedAmount / contractAmount * 100).toFixed(2));
    },

    totalProjects() {
      return this.projectDetails.length;
    },
    completedProjects() {
      return this.projectDetails.filter(p => p.progressStatus === 1).length;
    },
    incompleteProjects() {
      return this.totalProjects - this.completedProjects;
    }
  },
  methods: {
    formatAmount(amount) {
      return amount ? parseFloat(amount).toFixed(2) : '0.00';
    },
    loadProjectDetails() {
      getOwnerProjectDetails(this.project)
        .then(data => {
          this.projectDetails = data;
        })
        .catch(error => {
          console.error('Error loading project details:', error);
        });
    },
    getPieChartData() {
      const completedPercent = this.completionPercentage;
      const incompletePercent = (100 - completedPercent).toFixed(2);

      return {
        series: [
          {
            type: 'pie',
            radius: '90%',
            data: [
              { value: parseFloat(completedPercent), name: '已完成', itemStyle: { color: '#008CDC' } },
              { value: parseFloat(incompletePercent), name: '未完成', itemStyle: { color: '#D3D3D3' } }
            ],
            label: {
              show: true,
              position: 'inside',
              formatter: '{b}\n{d}%',
              fontSize: 12,
              fontWeight: 'bold',
              color: '#fff'
            },
            labelLine: {
              show: false
            }
          }
        ]
      };
    },
    toggleChartView() {
      this.isHalfView = !this.isHalfView;
    }
  },
  mounted() {
    this.loadProjectDetails();
  }
}
</script>

<style scoped>
.project-details-popup {
  width: 100%;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.detail-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.header-name {
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1; 
  white-space: nowrap;  
  overflow: hidden; 
  text-overflow: ellipsis;
}

.more {
  cursor: pointer;
  color: #999;
  flex-shrink: 0; 
  margin-left: 10px; 
}

.detail-content {
  padding: 10px;
}

.summary-row {
  margin-left: 20px;
  margin-bottom: 10px;
}

.summary-title {
  font-weight: bold;
  font-size: 14px;
}

.summary-stats {
  font-size: 14px;
}

.total-item {
  text-align: center;
}

.progress-value {
  font-size: 18px;
  font-weight: bold;
  color: #5196ff;
}

.progress-label {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  margin-top: 5px;
}

.progress-percentage {
  font-size: 24px;
  font-weight: bold;
  color: #5196ff;
}

.summary {
  margin: 20px 0;
}

.summary-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.detail-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 20px;
}

.detail-table th, .detail-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.detail-table th {
  background-color: #f2f2f2;
}

.loading {
  padding: 20px;
  text-align: center;
  color: #999;
}

.chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.chart-label {
  font-size: 14px;
  margin-bottom: 10px;
  color: #5196ff;
}

.progress-bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5196ff;
  clip: rect(0, 50px, 100px, 0);
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

.chart-value {
  font-size: 18px;
  font-weight: bold;
  color: #5196ff;
  margin-top: 10px;
}

.pie-chart {
  width: 120px;
  height: 120px;
  transition: all 0.3s ease;
}

.pie-slice {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.blue-slice {
  background: #36A2EB;
}

.gray-slice {
  background: #eee;
  transform: rotate(0deg);
}

.pie-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.completed {
  font-size: 12px;
  color: #1E90FF;
}

.incomplete {
  font-size: 12px;
  color: #808080;
  margin-bottom: 5px;
}

.percentage {
  font-size: 14px;
  font-weight: bold;
}

.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>