import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Icon, Text,Fill, Stroke } from 'ol/style'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import request from '@/utils/request'

const source = new VectorSource();

export const loadOwnerProjects = () => {
	request({
		url: 'common/table/ownerProjects',
		method: 'get',
	}).then(res => {
		const { data } = res
		let features = [];
		for (let i = 0; i < data.length; i++) {
			let item = data[i];
			let geometry = new Point([item.longitude, item.latitude]);
			let feature = new Feature({
				geometry: geometry.transform("EPSG:4326", "EPSG:3857")
			})
			feature.setStyle(new Style({
				image: new Icon({
					scale: 0.8,
					src: require('@/assets/images/map/owner-project.png')
				}),
				text: new Text({
					text: item.label,
					offsetX: 25, // Positive value to move text to the right of the icon
					offsetY: 8,  // Adjust if needed to vertically align with the icon
					font: 'bold 12px sans-serif',
					fill: new Fill({ color: '#1E90FF' }), // Dodger blue color for the text
					stroke: new Stroke({ color: '#FFFFFF', width: 8 }), // White outline
					textAlign: 'left',
					textBaseline: 'middle'
				})
			}))
			feature.setId(item.id);
			feature.set('data', item);
			feature.set('type', 'ownerproject');
			features.push(feature);
		}
		source.addFeatures(features);
	})
}

export const layer = () => {
	return new VectorLayer({
		source: source,
		declutter: true,
		// maxResolution: 50
	})
};

export const getOwnerProjectDetails = (project) => {
	return new Promise((resolve, reject) => {
		request({
			url: 'progress/getOwnerUnitProjects',
			method: 'get',
			params: { constructionProject: project.label }
		}).then(res => {
			const { data } = res
			resolve(data)
		}).catch(error => {
			reject(error)
		})
	})
}