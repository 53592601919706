import {getAisHistory} from "@/api/map";
import {fromLonLat} from 'ol/proj'
import { Feature } from 'ol'
import { Point, LineString } from 'ol/geom'
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Fill, Stroke, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {Toast} from "vant";

function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "y+": date.getFullYear().toString(), // 年
        "M+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "m+": date.getMinutes().toString(), // 分
        "s+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for(let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if(ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}
const trackSource = new VectorSource()
export const trackLayer = new VectorLayer({
    source: trackSource,
    declutter: true,
    style: feature => {
        let geometry = feature.getGeometry()
        if(geometry instanceof LineString) {
            return new Style({
                stroke: new Stroke({
                    color: [0, 0, 255],
                    width: 2
                })
            })
        } else {
            return new Style({
                image: new CircleStyle({
                    radius: 3,
                    fill: new Fill({
                        color: [0, 0, 255]
                    })
                }),
                text: new Text({
                    offsetX: -5,
                    text: feature.get('text'),
                    textAlign: 'right',
                    backgroundFill: new Fill({
                        color: [201, 220, 252, .8]
                    }),
                    backgroundStroke: new Stroke({
                        color: [0, 0, 0, 1],
                        width: 1
                    }),
                    padding: [4, 3, 2, 6],
                    font: '12px sans-serif'
                })
            })
        }
    }
})
export function clearTrack(){
    trackSource.clear()
}
export function loadTrack(mmsi, start, end, callback){
    Toast.loading({
        message: '查询中',
        forbidClick: true
    })
    getAisHistory(mmsi, start, end).then(res => {
        if(res.data && res.data.length > 0) {
            let coordinates = [];
            let features = [];
            for(let i = 0, len = res.data.length; i < len; i++) {
                let item = res.data[i];
                let coordinate = fromLonLat([item.longitude / 600000, item.latitude / 600000])
                coordinates.push(coordinate)
                features.push(new Feature({
                    geometry: new Point(coordinate),
                    text: dateFormat('yyyy-MM-dd HH:mm:ss', new Date(item.postime * 1000)) + (item.speed ? '  ' + (item.speed / 10) + '节' : '')
                }))
            }
            console.log('queryHistory')
            features.push(new Feature({
                geometry: new LineString(coordinates)
            }))
            trackSource.addFeatures(features)
            Toast.clear();
            callback && callback();
        } else {
            Toast.clear();
            Toast({
                message: '该时间段内无位置信息',
                duration: 2000,
            })
        }
    }).catch(() => Toast.clear())
}
