import { getDevicePos } from "@/api/map.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Circle as CircleStyle, Fill, Text, Stroke } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import getShipStyle from "@/views/index/map/ship-style";
import {toStringHDMS} from 'ol/coordinate'
let transformShip = (ship) => {
	ship.id = ship.shipId || ship.mmsi || ship.car_no || ship.TRUCK_NUM;
	ship.course = (ship.course !== undefined ? ship.course / 10 : undefined);
	ship.heading = ((ship.heading !== undefined && ship.heading != 511) ? ship.heading : undefined) || ship.direction;
	ship.speed = (ship.speed !== undefined ? ship.speed / 10 : undefined);
	ship.longitude = ship.longitude / 600000 || ship.lon || ship.LNG;
	ship.latitude = ship.latitude / 600000 || ship.LAT;
	ship.hdms = formateMousePosition(toStringHDMS([ship.longitude, ship.latitude]));
	ship.shipName = ship.name || ship.mmsi
	ship.shipLength = ship.totalLen
	ship.breadth = ship.profileWidth
	ship.imgUrls = ship.imgUrl != null ? ship.imgUrl.split(",") : []
}
let formateMousePosition = (args) => {
    try {
		var index = Number(args.indexOf('N'));
		if(index == -1) {
			index = Number(args.indexOf('S'));
		}
		var strLat = args.substring(0, index + 1);
		var strLon = args.substring(index + 1, args.length);
		var arrLat = strLat.split(" ");
		var arrLon = strLon.split(" ");
		var fen = Number(arrLat[2].substring(0, arrLat[2].length - 1)) / 60;
		fen = Number(fen.toFixed(3));
		fen = Number(arrLat[1].substring(0, arrLat[1].length - 1)) + Number(fen);
		fen = fen.toFixed(3) + arrLat[1].substring(arrLat[1].length - 1, arrLat[1].length);
		var reLat = arrLat[0] + fen + arrLat[3];
		var fen2 = Number(arrLon[3].substring(0, arrLon[3].length - 1)) / 60;
		fen2 = Number(fen2.toFixed(3));
		fen2 = Number(arrLon[2].substring(0, arrLon[2].length - 1)) + Number(fen2.toFixed(3));
		fen2 = fen2.toFixed(3) + arrLon[2].substring(arrLon[2].length - 1, arrLon[2].length);
		var reLon = arrLon[1] + fen2 + arrLon[4];
		return {
			x: reLon,
			y: reLat
		};
	} catch(e) {
		console.log(e);
	}
}
let source = new VectorSource();
getDevicePos().then(res => {
	const {
		data
	} = res;
	if(data) {
		for(let i = 0; i < data.length; i++) {
			let ship = data[i]
			ship.type = 'locator';
			transformShip(ship)
			let shipFeature = source.getFeatureById(ship.id)
			if(!shipFeature) {
				shipFeature = new Feature({
					geometry: new Point(fromLonLat([ship.longitude, ship.latitude]))
				})
				shipFeature.setId(ship.id);
				shipFeature.set('data', ship);
				shipFeature.set('type', 'ship');
				source.addFeature(shipFeature);
			}
		}
	}
})
export default map => {
	return new VectorLayer({
		source: source,
		zIndex: 100,
		style: (feature, res) => {
			var ship = feature.get('data')
			var text = '' + (ship.shipNameCh || ship.shipNameEn || ship.shipName || ship.mmsi || ship.TRUCK_NUM);
			var presentStyle = ship.isPresent == '1' ? new Style({
				text: new Text({
					offsetX: 51,
					offsetY: -21,
					text: '在',
					textAlign: 'center',
					fill: new Fill({
						color: 'white'
					}),
					padding: [4, 3, 2, 2],
				}),
				image: new CircleStyle({
					radius: 8,
					fill: new Fill({
						color: [255, 152, 0, 1]
					}),
					displacement: [51, 21]
				}),
				zIndex: 6
			}) : undefined;
			var textStyles = [
				new Style({
					text: new Text({
						offsetX: presentStyle ? 61 : 48,
						offsetY: -20,
						text: text,
						textAlign: 'left',
						backgroundFill: new Fill({
							color: [255, 255, 255, 1]
						}),
						backgroundStroke: new Stroke({
							color: [39, 112, 215, 1],
							width: 1
						}),
						padding: [4, 3, 2, 47],
						font: '12px sans-serif'
					}),
					zIndex: 4
				}),
				new Style({
					text: new Text({
						offsetX: 7,
						offsetY: -20,
						text: (ship.deviceType == '1' ? 'AIS' : 'BDS'),
						textAlign: 'center',
						backgroundFill: new Fill({
							color: [39, 112, 215, 1]
						}),
						backgroundStroke: new Stroke({
							color: [39, 112, 215, 1],
							width: 1
						}),
						fill: new Fill({
							color: 'white'
						}),
						padding: [4, 3, 2, 3],
						font: '12px sans-serif'
					}),
					zIndex: 5
				}),
				new Style({
					text: new Text({
						offsetX: 34,
						offsetY: -21,
						text: '工',
						textAlign: 'center',
						fill: new Fill({
							color: 'white'
						}),
						padding: [4, 3, 2, 2],
					}),
					image: new CircleStyle({
						radius: 8,
						fill: new Fill({
							color: 'red'
						}),
						displacement: [34, 21]
					}),
					zIndex: 6
				})
			]
			if(presentStyle) {
				textStyles.push(presentStyle)
			}
			return getShipStyle(map, feature.getGeometry().getCoordinates(), ship.breadth, ship.shipLength, ship.speed, ship.heading, ship.course, ship.postime, textStyles, '#BAFF00', feature.get('selected'), res, ship.forecast)
		}
	})
}
