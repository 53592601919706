<template>
	<div class="container">
		<van-nav-bar :style="urlValue == '' ? '':{'margin-top':'30px'}" :title="selectData.name || selectData.shipName || selectData.mmsi" :left-text="returnText" :left-arrow="''" @click-left="closeDetail" />
		<div :style="urlValue == '' ? {'overflow-y':'auto','height':'100%'}:{'overflow-y':'auto','height':'calc(100% - 80px)'}">
			<el-card class="box-card">
				<div class="detail-header">
					<div class="header-name">{{selectData.name || selectData.shipName || selectData.mmsi}}</div>
					<div class="position">{{selectData.deviceType == '1' ? 'AIS' : 'BDS'}}</div>
					<div class="ship-type" v-if="selectData.isPresent == 1">工 </div>
				</div>
				<div class="ship-detail-list">
					<template>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">船舶类型:</span>
								<span class="item-value">{{selectData.shipTypeName}}</span>
							</div>
							<div class="ship-item3">
								<span class="item-label">MMSI:</span>
								<span class="item-value">{{selectData.mmsi}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">总长:</span>
								<span class="item-value">{{selectData.shipLength || '-' }}米</span>
							</div>
							<div class="ship-item3">
								<span class="item-label">船长:</span>
								<span class="item-value">{{selectData.shipLen || '-' }}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">型深:</span>
								<span class="item-value">{{selectData.shapeDepth || '-' }}</span>
							</div>
							<div class="ship-item3">
								<span class="item-label">船宽:</span>
								<span class="item-value">{{selectData.breadth || '-' }}米</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">经度:</span>
								<span class="item-value">{{selectData.hdms.x || '-'}}</span>
							</div>
							<div class="ship-item3">
								<span class="item-label">纬度:</span>
								<span class="item-value">{{selectData.hdms.y || '-'}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">航向:</span>
								<span class="item-value">{{selectData.course}}</span>
							</div>
							<div class="ship-item3">
								<span class="item-label">速度:</span>
								<span class="item-value">{{selectData.speed}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item2">
								<span class="item-label">报位时间:</span>
								<span class="item-value2">{{dateFormat('yyyy-MM-dd HH:mm:ss',new Date((selectData.postime || 0) * 1000))}}</span>
							</div>
						</div>
					</template>
				</div>
				<div>
					<div class="ship-detail-list2">
						<template>
							<div class="ship-list-item">
								<div class="ship-item2">
									<span class="item-label">总吨位:</span>
									<span class="item-value">{{selectData.grossTonnage}}</span>
								</div>
								<div class="ship-item3">
									<span class="item-label">净吨位:</span>
									<span class="item-value">{{selectData.netTonnage}}</span>
								</div>
							</div>
							<div class="ship-list-item">
								<div class="ship-item2">
									<span class="item-label">空载排水量:</span>
									<span class="item-value">{{selectData.noLoadDisplacement }}</span>
								</div>
								<div class="ship-item3">
									<span class="item-label">空载吃水:</span>
									<span class="item-value">{{selectData.noLoadDraft }}</span>
								</div>
							</div>
							<div class="ship-list-item">
								<div class="ship-item2">
									<span class="item-label">满载排水量:</span>
									<span class="item-value">{{selectData.fullLoadDisplacement }}</span>
								</div>
								<div class="ship-item3">
									<span class="item-label">满载吃水:</span>
									<span class="item-value">{{selectData.fullDraft }}</span>
								</div>
							</div>
							<div class="ship-list-item">
								<div class="ship-item2">
									<span class="item-label">钻杆型号:</span>
									<span class="item-value" style="width: calc(100% - 52px);">{{selectData.drillPipeModel}}</span>
								</div>
								<div class="ship-item3">
									<span class="item-label">最大功率:</span>
									<span class="item-value">{{selectData.maximumPower}}</span>
								</div>
							</div>
						</template>
					</div>
				</div>
			</el-card>
			<el-card class="box-card">
				<div class="detail-header">
					<div class="header-name">船员信息</div>
					<div class="ship-personnel">{{shipPersonnelData.length}}</div>
					<div class="ship-personnel-date">{{inputDate}} </div>
				</div>
				<div class="ship-detail-table">
					<template>
						<el-table :data="shipPersonnelData" :header-cell-style="{'font-weight':'normal'}" size="mini">
							<el-table-column prop="name" label="姓名">
							</el-table-column>
							<el-table-column prop="sex" label="性别" width="35">
							</el-table-column>
							<el-table-column prop="duties" label="职务" width="60">
							</el-table-column>
							<el-table-column prop="inTime" label="进场时间" width="70">
							</el-table-column>
							<el-table-column prop="outTime" label="离场时间" width="70">
							</el-table-column>
						</el-table>
					</template>
				</div>
			</el-card>
			<el-card class="box-card">
				<div class="detail-header">
					<div class="header-name">作业信息</div>
				</div>
				<div class="ship-detail-table">
					<template>
						<el-table :data="jobInformationData" :header-cell-style="{'font-weight':'normal'}" size="mini">
							<el-table-column prop="inputDate" label="时间">
							</el-table-column>
							<el-table-column prop="site" label="施工地点" width="70">
							</el-table-column>
							<el-table-column prop="personnelNum" label="人数" width="40">
							</el-table-column>
							<el-table-column prop="dailyQuantities" label="日" width="30">
							</el-table-column>
							<el-table-column prop="cumulativeQuantities" label="累计" width="40">
							</el-table-column>
							<el-table-column prop="inputPersonnel" label="填报人" width="60">
							</el-table-column>
						</el-table>
					</template>
				</div>
			</el-card>
			<el-card class="box-card">
				<div class="detail-header">
					<div class="header-name">附属信息</div>
				</div>
				<div class="ship-detail-list2">
					<template>
						<div class="ship-list-item">
							<div class="ship-item4">
								<el-carousel height="100px">
									<div v-if="selectData.imgUrls.length > 0">
										<el-carousel-item v-for="item in selectData.imgUrls" :key="item" style="text-align: center;">
											<el-image style="height: 100px" :src="'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/'+item" fit="fill"></el-image>
										</el-carousel-item>
									</div>
								</el-carousel>
							</div>
							<div class="ship-item5">
								<span class="item-label">所属单位:</span>
								<span class="item-value">{{selectData.affiliatedUnit}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item5">
								<span class="item-label">建造时间:</span>
								<span class="item-value">{{selectData.buildDate}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item5">
								<span class="item-label">进场时间:</span>
								<span class="item-value">{{selectData.entryDate}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item5">
								<span class="item-label">离场时间:</span>
								<span class="item-value">{{selectData.departureDate}}</span>
							</div>
						</div>
						<div class="ship-list-item">
							<div class="ship-item4">
								<span class="item-label">负责人:</span>
								<span class="item-value">{{selectData.leadingCadre}}</span>
							</div>
							<div class="ship-item5">
								<span class="item-label">通讯方式:</span>
								<span class="item-value">{{selectData.contactInfo}}</span>
							</div>
						</div>
					</template>
				</div>
			</el-card>
		</div>
	</div>
</template>
<script>
	import { getShipAllPersonnelStatus, getShipDailyList } from "@/api/map.js";
	import { getQueryString } from '@/utils/utils'
	export default {
		name: 'ShipDetail',
		props: ['selectData'],
		data() {
			return {
				urlValue: '',
				returnText: "",
				shipPersonnelData: [],
				jobInformationData: []
			}
		},
		created() {
			var value = getQueryString('component')
			if(value != null && value != '') {
				this.urlValue = value
			}
			console.log(this.urlValue)
			this.getShipPersonnelData();
			this.getJobInformationData();
		},
		methods: {
			closeDetail() {
				this.$emit('closeDetail');
			},
			getShipPersonnelData() {
				this.inputDate = this.dateFormat('yyyy-MM-dd', new Date())
				getShipAllPersonnelStatus((this.selectData.shipId + '').replace('custom', ''), this.inputDate).then(res => {
					this.shipPersonnelData = res.data != null ? res.data : []
				})
			},
			getJobInformationData() {
				getShipDailyList((this.selectData.shipId + '').replace('custom', '')).then(res => {
					this.jobInformationData = res.data != null ? res.data : []
				})
			},
			dateFormat(fmt, date) {
				let ret;
				const opt = {
					"y+": date.getFullYear().toString(), // 年
					"M+": (date.getMonth() + 1).toString(), // 月
					"d+": date.getDate().toString(), // 日
					"H+": date.getHours().toString(), // 时
					"m+": date.getMinutes().toString(), // 分
					"s+": date.getSeconds().toString() // 秒
					// 有其他格式化字符需求可以继续添加，必须转化成字符串
				};
				for(let k in opt) {
					ret = new RegExp("(" + k + ")").exec(fmt);
					if(ret) {
						fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
					}
				}
				return fmt;
			}
		}
	}
</script>
<style scoped>
.detail-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.header-name {
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1;  
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.more {
  cursor: pointer;
  color: #999;
  flex-shrink: 0; 
  margin-left: 10px; 
}

.el-card__body,
.el-main {
	padding: 5px;
}
.van-nav-bar .van-icon{
	color: #1989fa;
}

.container {
	background-color: #E5E5E5;
}

.box-card {
	margin: 5px;
}
	
</style>