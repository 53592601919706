import { alarmList } from "@/api/map.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Text, Stroke, Circle as CircleStyle, Fill } from 'ol/style'
import { getVectorContext } from 'ol/render'
import { easeOut } from 'ol/easing';
//import { Style, Icon,  Text } from 'ol/style'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
const source = new VectorSource();
/*
export default new VectorLayer({
    source: source,
    declutter: true,
    maxResolution: 150
})*/

let flashAlarm = (map, alarmVector) => {
	var start = new Date().getTime();
	alarmVector.on('postrender', animateAlarm);

	function animateAlarm(event) {
		var vectorContext = getVectorContext(event);
		var frameState = event.frameState;
		var elapsed = frameState.time - start;
		var elapsedRatio = (elapsed % 3000) / 3000;
		// radius will be 5 at start and 30 at end.
		var radius = easeOut(elapsedRatio) * 20 + 5;
		var opacity = easeOut(1 - elapsedRatio);
		source.getFeatures().forEach(feature => {
			var flashGeom = feature.getGeometry().clone();
			var style = new Style({
				image: new CircleStyle({
					radius: radius,
					stroke: new Stroke({
						color: 'rgba(255, 0, 0, ' + opacity + ')',
						width: 0.25 + opacity,
					}),
				}),
			});
			vectorContext.setStyle(style);
			vectorContext.drawGeometry(flashGeom);
		})
		map.render();
	}
}

export const loadAlarms = (current) => {
	alarmList(current).then(res => {
		const { data } = res.data;
		
		if (data && Array.isArray(data) && data.length > 0) {
			let features = [];
			for(let i = 0; i < data.length; i++) {
				let item = data[i];
				if (item && item.latitude && item.longitude) {
					try {
						item.lat = (item.latitude / 600000).toFixed(3);
						item.lon = (item.longitude / 600000).toFixed(3);
						let geometry = new Point([item.lon, item.lat]);
						let feature = new Feature({
							geometry: geometry.transform("EPSG:4326", "EPSG:3857")
						});
						feature.setId(item.id);
						feature.set('data', item);
						feature.set('type', 'alarm');
						feature.setStyle([new Style({
							image: new CircleStyle({
								radius: 7,
								stroke: new Stroke({
									color: 'red'
								})
							}),
							text: new Text({
								text: item.shipName || item.mmsi,
								textBaseline: 'top',
								offsetX: 30,
								stroke: new Stroke({
									color: '#fff',
									width: 5
								}),
								fill: new Fill({
									color: 'rgb(255 0 0)'
								})
							}),
						})]);
						features.push(feature);
					} catch (error) {
						console.error(`Error processing alarm ${item.id}:`, error);
					}
				}
			}
			source.addFeatures(features);
		} else {
			console.warn('No valid alarm data received');
		}
	}).catch(error => {
		console.error('Error fetching alarm data:', error);
	});
}

export const layer = (map) => {
	let alarmVector = new VectorLayer({
		source: source,
		declutter: true,
		maxResolution: 150
	})
	flashAlarm(map, alarmVector)
	return alarmVector
};