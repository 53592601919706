<template>
  <div class="map-container">
    <van-popup 
      v-model="showPopup"  
      :position="urlValue === '' ? 'bottom':'bottom bottom-full'" 
      round 
      :overlay="false" 
      :closeable="true" 
      @close="closePopup"
    >
      <div class="detail-header-box">
        <div class="header-left-icon"><span class="icon water-droplet"></span></div>
        <div class="header-name">{{watersiteData.waterSite}}</div>
      </div>
      <div id="echart-box" ref="echart" class="detail-profile-box">
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getWaterSiteList } from '@/api/watersite.js';
import { getQueryString } from '@/utils/utils'
import * as echarts from 'echarts'

export default {
  name: 'WatersitePopup',
  props: {
    selectData: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      urlValue: '',
      showPopup: false,
      watersiteData: {},
      waterLevelInputDate: [],
      chart: null
    }
  },
  watch: {
    selectData: {
      handler(newValue) {
        console.log('selectData changed:', newValue);
        this.initData();
        if (this.showPopup) {
          this.getWaterSiteData(newValue);
        }
      },
      immediate: true
    },
    show: {
      handler(newVal) {
        console.log('show prop changed:', newVal);
        this.showPopup = newVal;
        if (newVal) {
          this.$nextTick(() => {
            this.initChart();
          });
        } else {
          if (this.chart) {
            this.chart.dispose();
            this.chart = null;
          }
        }
      },
      immediate: true
    }
  },
  created() {
    var component = getQueryString('component')
    if(component != null && component != '') {
      this.component = component
      this.urlValue = component
    }
    this.initData()
  },
  mounted() {
    console.log('WatersitePopup mounted, showPopup:', this.showPopup);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    initData() {
      if(!this.selectData.waterSite) {
        this.watersiteData = {
          "waterSite": "镇江(定易洲)",
          "coordinates": "[107.078617,29.817217]"
        }
      } else {
        this.watersiteData = this.selectData
      }
      
      const today = new Date();
      const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      this.waterLevelInputDate = [
        this.dateFormat('yyyy-MM-dd', sevenDaysAgo),
        this.dateFormat('yyyy-MM-dd', today)
      ];
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose();
      }
      const chartDom = this.$refs.echart;
      if (!chartDom) {
        console.error('Chart DOM element not found');
        return;
      }
      this.chart = echarts.init(chartDom);
      this.getWaterSiteData(this.watersiteData);
    },
    getWaterSiteData(data) {
      getWaterSiteList(data.waterSite, this.waterLevelInputDate[0], this.waterLevelInputDate[1])
        .then(res => {
          const rawData = res.data || [];
          rawData.sort((a, b) => new Date(a.inputDate) - new Date(b.inputDate));

          // Group data by date
          const groupedByDate = rawData.reduce((acc, item) => {
            const date = item.inputDate.split(' ')[0];
            if (!acc[date]) acc[date] = [];
            acc[date].push(item);
            return acc;
          }, {});

          const filteredData = [];
          const targetTimes = ['08:00:00', '20:00:00'];
          const maxDiffHours = 3;

          Object.keys(groupedByDate).forEach(date => {
            const dayData = groupedByDate[date];
            
            targetTimes.forEach(targetTime => {
              // Try to find exact match first
              const exactMatch = dayData.find(item => 
                item.inputDate.endsWith(targetTime)
              );

              if (exactMatch) {
                filteredData.push(exactMatch);
              } else {
                // Find nearest later time within 3 hours
                const targetHour = parseInt(targetTime.split(':')[0]);
                let bestMatch = null;
                let minTimeDiff = Infinity;

                dayData.forEach(item => {
                  const itemHour = parseInt(item.inputDate.split(' ')[1].split(':')[0]);
                  // Calculate time difference (positive for later times, negative for earlier times)
                  const hourDiff = itemHour - targetHour;
                  
                  // Only consider times that are up to 3 hours later
                  if (hourDiff >= 0 && hourDiff <= maxDiffHours && hourDiff < minTimeDiff) {
                    minTimeDiff = hourDiff;
                    bestMatch = item;
                  }
                });

                // If no later time found within 3 hours, try earlier times
                if (!bestMatch) {
                  dayData.forEach(item => {
                    const itemHour = parseInt(item.inputDate.split(' ')[1].split(':')[0]);
                    const hourDiff = targetHour - itemHour;
                    
                    if (hourDiff > 0 && hourDiff <= maxDiffHours && hourDiff < minTimeDiff) {
                      minTimeDiff = hourDiff;
                      bestMatch = item;
                    }
                  });
                }

                if (bestMatch) {
                  filteredData.push(bestMatch);
                }
              }
            });
          });

          // Sort final filtered data
          filteredData.sort((a, b) => new Date(a.inputDate) - new Date(b.inputDate));

          const xAxisData = filteredData.map(d => d.inputDate);
          const seriesData = filteredData.map(d => d.waterHeight);
          
          let [min, max] = [Math.min(...seriesData), Math.max(...seriesData)];
          let range = max - min;
          let padding = range * 0.1;
          [min, max] = [parseFloat((min - padding).toFixed(2)), parseFloat((max + padding).toFixed(2))];
          
          if (this.chart) {
            this.chart.setOption({
              grid: {
                top: '20%',
                bottom: '25%',
                left: '5%',
                right: '5%'
              },
              xAxis: {
                type: 'category',
                data: xAxisData,
                axisLabel: {
                  formatter: function(value) {
                    var datePart = value.split(' ')[0];
                    var timePart = value.split(' ')[1];
                    var formattedDatePart = datePart.slice(5, 10);
                    var formattedTimePart = timePart.slice(0, 5);
                    return formattedTimePart + '\n' + formattedDatePart;
                  },
                  interval: Math.floor(xAxisData.length / 8),
                  rotate: 45,
                  textStyle: {
                    fontSize: 10
                  }
                }
              },
              yAxis: {
                name: '水位值(m)',
                type: 'value',
                min: min,
                max: max,
                nameTextStyle: {
                  padding: [0, 0, 0, 40]
                }
              },
              series: [{
                data: seriesData,
                type: 'line',
                smooth: true,
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c}',
                  textStyle: {
                    fontSize: 10
                  }
                }
              }],
              tooltip: {
                trigger: 'axis',
                formatter: function(params) {
                  var date = params[0].name;
                  var value = params[0].value;
                  return date + '<br/>水位: ' + value + 'm';
                }
              }
            });
          }
        })
        .catch(error => {
          console.error('Error fetching water site data:', error);
        });
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "y+": date.getFullYear().toString(),
        "M+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "m+": date.getMinutes().toString(),
        "s+": date.getSeconds().toString()
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
      }
      return fmt;
    },
    closePopup() {
      this.showPopup = false;
      this.$emit('update:show', false);
    }

  }
}
</script>

<style scoped>
    .van-popup--bottom {
        bottom: 50px;
        max-height: 80%;
    }
    
    .van-popup--bottom-full {
        bottom: 50px;
        max-height: 80%;
    }
    
    .detail-header-box {
        padding: 10px 10px 0px 10px;
        height: 40px;
    }
    
    .detail-header-box .header-left-icon,
    .detail-header-box .header-right-icon {
        font-family: iconfont;
        color: #2770d7;
        font-weight: 700;
        font-size: 0.5rem;
        height: 1rem;
        width: 30px;
        float: left;
    }
    
    .detail-header-box .header-name {
        font-size: 18px;
		font-weight: bold;
        color: #45474f;
        float: left;
        margin-left: 5px;
    }
    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        color: #999;
        font-size: 14px;
    }

    .detail-header-box .header-more {
        cursor: pointer;
        color: #999;
    }
    
    .detail-header-box .position {
        margin-left: 10px;
        margin-top: 1px;
        font-size: 10px;
        background-color: #80D3F8;
        color: white;
        padding: 3px 5px 3px 5px;
        border-radius: 6px;
        float: left;
    }
    
    .detail-header-box .icon {
        background-size: cover;
        background-position: center;
        display: inline-block;
    }
    
    .water-droplet {
        width: 27px;
        padding-top: 24px;
        background: url("~@/assets/images/map/watersite/water-droplet.png") no-repeat;
    }
    
    .uncollection {
        width: 40px;
        padding-top: 22px;
        background: url("~@/assets/images/map/watersite/uncollection.png") no-repeat;
    }
    
    .collection {
        width: 40px;
        padding-top: 22px;
        background: url("~@/assets/images/map/watersite/collection.png") no-repeat;
    }
    
    .detail-content-box {
        height: 35px;
        line-height: 35px;
        margin-left: 15px;
        margin-right: 15px;
        background-color: #F6F8FA;
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
        width: calc(100% - 60px);
    }
    
    .detail-content-box .content-box {
        float: left;
        width: 30%;
    }
    
    .detail-content-box .content-box .content-title {
        float: left;
        width: 45%;
        color: #797979;
        font-weight: 800;
    }
    
    .detail-content-box .content-box .content-value {
        float: left;
        width: 55%;
        text-align: right;
        color: #797979;
        margin-top: 1px;
        font-weight: 600;
    }
    
    .detail-content-box .content-box:nth-of-type(n+2) {
        margin-left: 5%;
        color: #797979;
    }
    
    .detail-profile-box {
        height: 180px;
        margin: 15px 15px 15px 15px;
        border-width: medium;
        border-color: #DDDDDD;
    }
    
    .watersite-details-page {
        width: 100%;
        z-index: 99999;
        position: fixed;
        overflow: scroll!important;
    }
</style>