import { getCameraList } from "@/api/map.js";
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Icon, Text, Fill, Stroke } from 'ol/style'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
const source = new VectorSource();
getCameraList().then(res => {
	const {
		data
	} = res
	let features = [];
	for(let i = 0; i < data.length; i++) {
		data[i].deviceStatus = 1; // 临时设置为全部在线
		let coordinate = [Number(data[i].longitude), Number(data[i].latitude)];
		let geometry = new Point(coordinate);
		let feature = new Feature({
			geometry: geometry.transform("EPSG:4326", "EPSG:3857")
		})
		feature.setStyle(new Style({
			image: new Icon({
				src: require('@/assets/images/map/camera' + (data[i].deviceStatus != '1' ? '-offline' : '') + '.png'),
				scale: 0.9
			}),
			text: new Text({
				text: data[i].name,
				textBaseline: 'top',
				offsetX: 40,
				stroke: new Stroke({
					color: '#fff',
					width: 5
				}),
				fill: new Fill({
					color: 'rgb(0,72,255,1)'
				})
			}),
			zIndex: 0
		}))
		feature.setId(data[i].id);
		feature.set('data', data[i]);
		feature.set('type', 'camera');
		features.push(feature);
	}
	source.addFeatures(features);
})
export default new VectorLayer({
    source: source,
    declutter: true,
    maxResolution: 150
})