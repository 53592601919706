<template>
  <div class="chart" ref="chart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'Chart',
  props: {
    data: Object
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart)
    this.update() // add this line
  },
  methods: {
    update() {
      this.chart.setOption(this.data)
    }
  },
  watch: {
    data() {
      this.update()
    }
  }
}
</script>
<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 100%;
}
</style>
